<template>
  <div class="pt-16">
    <v-form
      @submit.prevent="valid ? submit : validate"
      ref="form"
      v-model="valid"
      class="white--text px-5 pb-10"
    >
      <v-row class="pt-16 px-10 fill-height">
        <v-col>
          <h1 class="display-1 mt-8">Application For Employment</h1>
          <p>
            Please fully and accurately complete each section. Incomplete
            applications may not be considered.
          </p>
        </v-col>
      </v-row>
      <v-row class="px-10 fill-height">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="application.first"
            :rules="textRules"
            label="First Name"
            hide-details="auto"
            name="firstName"
            outlined
            dark
            class="mb-5 white--text"
          />
          <v-text-field
            v-model="application.last"
            :rules="textRules"
            label="Last Name"
            hide-details="auto"
            name="lastName"
            outlined
            dark
            class="mb-5"
          />
          <v-text-field
            v-model="application.email"
            :rules="emailRules"
            label="Email"
            hide-details="auto"
            append-icon="mdi-email-outline"
            name="email"
            outlined
            dark
            class="mb-5"
          />
          <v-text-field
            v-model="application.phone"
            :rules="phoneRules"
            label="Phone"
            append-icon="mdi-phone"
            hide-details="auto"
            type="tel"
            name="phone"
            outlined
            dark
            class="mb-5"
          />
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="application.date"
                :rules="textRules"
                label="Date"
                append-icon="mdi-calendar"
                readonly
                name="date"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                dark
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="application.date"
              @input="menu = false"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="application.positionType"
            :rules="textRules"
            label="Position Desired"
            hide-details="auto"
            :items="positions"
            name="position"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            dark
            class="mb-5"
          />
          <v-text-field
            v-model="application.salaryExpectation"
            label="Salary Expected"
            hide-details="auto"
            type="number"
            name="salary"
            prepend-inner-icon="mdi-currency-usd"
            outlined
            dark
            class="mb-5"
          />
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="application.startDate"
                :rules="textRules"
                label="Date Available"
                append-icon="mdi-calendar"
                readonly
                name="dateAvailable"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                dark
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="application.startDate"
              @input="startMenu = false"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="px-10 fill-height">
        <v-col>
          <v-divider dark class="my-5" />
          <label>
            List job benefits - other than wages - you expect or want in order
            of importance
          </label>
          <v-textarea
            v-model="application.benefits"
            :rules="textRules"
            label="List at least 3"
            hide-details="auto"
            name="benefits"
            class="mb-4"
            outlined
            rows="3"
            dark
          />
          <label>
            Describe your prior work experience related to this work
          </label>
          <v-textarea
            v-model="application.experience"
            :rules="textRules"
            hide-details="auto"
            name="priorWork"
            class="mb-4"
            outlined
            rows="3"
            dark
          />
          <label>
            Describe any formal schooling or training related to this work
          </label>
          <v-textarea
            v-model="application.schooling"
            :rules="textRules"
            hide-details="auto"
            name="schooling"
            class="mb-4"
            outlined
            rows="3"
            dark
          />
          <label>
            List any special skills you may have (computer, machine, operation,
            etc.)
          </label>
          <v-textarea
            v-model="application.skills"
            :rules="textRules"
            hide-details="auto"
            name="skills"
            outlined
            rows="3"
            dark
          />
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col>
          <label>Availability For Work</label>
          <div class="d-flex">
            <v-checkbox
              v-model="application.availablity"
              dark
              class="pt-0 mt-0 pr-3"
              color="primary"
              label="Part Time"
              value="Part Time"
              name="availability"
            />
            <v-checkbox
              v-model="application.availablity"
              dark
              class="pt-0 mt-0 pr-3"
              color="primary"
              label="Full Time"
              value="Full Time"
              name="availability"
            />
            <v-checkbox
              v-model="application.availablity"
              dark
              class="pt-0 mt-0 pr-3"
              color="primary"
              label="Temporary or Short Term"
              value="Temporary or Short Term"
              name="availability"
            />
          </div>
          <label>Will you work daily overtime on occasion if necessary?</label>
          <v-radio-group
            v-model="application.overtime"
            name="overtime"
            :rules="radioRules"
            row
            class="mt-0 pt-0"
          >
            <v-radio
              v-for="n in bool"
              :key="n.i"
              :label="n.text"
              :value="n.value"
              dark
              color="primary"
            ></v-radio>
          </v-radio-group>
          <label>Will you work extra days in the week if necessary?</label>
          <v-radio-group
            v-model="application.extraDays"
            name="extra"
            :rules="radioRules"
            row
            class="mt-0 pt-0"
          >
            <v-radio
              v-for="n in bool"
              :key="n.i"
              :label="n.text"
              :value="n.value"
              dark
              color="primary"
            ></v-radio>
          </v-radio-group>
          <label
            >Do you have any on-going obligations or other personal commitments
            that would affect your work schedule?</label
          >
          <v-radio-group
            v-model="application.obligations"
            row
            class="mt-0 pt-0"
            :rules="radioRules"
            name="obligations"
          >
            <v-radio
              v-for="n in bool"
              :key="n.i"
              :label="n.text"
              :value="n.value"
              dark
              color="primary"
            ></v-radio>
          </v-radio-group>
          <v-divider dark class="py-2" />
        </v-col>
      </v-row>
      <v-row class="px-10 mt-0 pb-10">
        <v-col>
          <label>Are you 18 years old?</label>
          <v-radio-group
            v-model="application.age"
            name="age"
            :rules="radioRules"
            row
            class="mt-0 pt-0"
          >
            <v-radio
              v-for="n in bool"
              :key="n.i"
              :label="n.text"
              :value="n.value"
              dark
              color="primary"
              required
            ></v-radio>
          </v-radio-group>
          <label
            >If hired, can you finish proof that you are eligible to work in the
            U.S.?</label
          >
          <v-radio-group
            v-model="application.eligible"
            name="eligible"
            :rules="radioRules"
            row
            class="mt-0 pt-0"
          >
            <v-radio
              v-for="n in bool"
              :key="n.i"
              :label="n.text"
              :value="n.value"
              dark
              color="primary"
            ></v-radio>
          </v-radio-group>
          <div class="d-flex align-center">
            <label> Upload a Resume </label>
            <v-btn icon outlined dark class="mx-5" @click="onPickFile">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <p v-if="resume" class="mb-0 primary--text" @click="onPickFile">
              {{ this.application.resume.name }}
            </p>
            <input
              type="file"
              style="display: none"
              ref="resume"
              accept=".pdf,.doc,.docx,application/msword"
              @change="onFilePicked"
            />
          </div>
          <v-divider dark class="mt-6" />
          <div class="pt-5">
            <h3>
              APPLICATION AGREEMENT <em>(Read carefully before signing.)</em>
            </h3>
          </div>
          <p>By submitting this application I agree to the following:</p>
          <p v-for="t in agreement" :key="t.i">
            {{ t }}
          </p>
          <h3>AN EQUAL OPPORTUNITY EMPLOYER</h3>
          <!-- :disabled="!valid" -->
          <v-btn
            type="submit"
            color="primary"
            outlined
            rounded
            @click="validate"
            class="mt-3"
            v-show="!sent"
          >
            Submit Application
          </v-btn>
          <v-slide-x-transition>
            <div class="primary--text mt-3 d-flex align-baseline" v-show="sent">
              APPLICATION SENT
              <v-icon color="primary" class="ml-2"> mdi-check </v-icon>
            </div>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar">
      <v-icon color="primary">mdi-check</v-icon>
      Application Submitted

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  positionsCollection,
  applicationsCollection,
  storage,
} from "../firebase/credentials";
import emailjs from "emailjs-com";
import moment from "moment";
export default {
  data() {
    return {
      position: {},
      sent: false,
      snackbar: false,
      valid: false,
      application: { availablity: [""] },
      textRules: [(v) => !!v || "Value is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      radioRules: [(v) => v == true || v == false || "Please select a value"],
      phoneRules: [
        (v) => !isNaN(v) || "Phone must be number",
        (v) => !!v || "Phone is required",
      ],
      positions: [],
      resume: false,
      bool: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      menu: false,
      startMenu: false,
      agreement: [
        `All information provided by me is true and correct to the best of my knowledge. 
      I understand emissions or misrepresentations may result in rejection of my application or, if 
      employed, may result in subsequent dismissal. I hereby authorize any former employer, person,
       school, firm or corporation listed hereon, including this company, to answer any and all 
       questions related to employment and agree to release from liability and hold all persons 
       harmless for giving any and all truthful information within their knowledge or records. I 
       understand this is a preliminary application and not a contract to employ me. Furthermore, in
        the event I am employed, my employment shall be completely voluntary and may be terminated 
        at will at any time by either myself or the company. I understand that no one other than the 
        company president has authority to enter into any employment agreement to the contrary. I 
        agree to take a job related physical examination or a drug/alcohol test when requested as 
        a condition of employment. I agree to comply with all rules of the company as a condition 
        of employment. In the event the company advances me money or other things of value, I agree 
        to repay the company and also that any amount still owing may be deducted from my final 
        paycheck.`,
        `Qualified applicants receive equal consideration. No question is asked for the purpose 
        of excluding any applicant due to race, color, national origin, religion, age, sex, 
        disability, or any other factor prohibited by law or regulation.`,
      ],
    };
  },
  methods: {
    onPickFile() {
      this.$refs.resume.click();
    },
    validate() {
      let result = this.$refs.form.validate();
      if (result) this.submit();
      else window.scrollTo(0, 0);
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    onFilePicked(event) {
      Array.from(event.target.files).forEach((file) => {
        let fileName = file.name;
        if (fileName.lastIndexOf(".") <= 0) {
          return alert("Please add a valid document.");
        }

        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.application.resume = {
            temp: fileReader.result,
            file: file,
            path: `/uploads/applications/${file.name}`,
          };
        });
        fileReader.readAsDataURL(file);
      });
    },
    async submit() {
      try {
        if (
          this.application.resume != undefined &&
          this.application.resume != null
        ) {
          await storage
            .ref(this.application.resume.path)
            .put(this.application.resume.file);

          this.application.resume = this.application.resume.path;
        }

        await applicationsCollection.add(this.application);

        this.snackbar = true;
        this.sent = true

        try {
          emailjs.sendForm(
            "service_beao3vp",
            "template_zb25q3b",
            document.querySelector("form"),
            "user_Wo4Fk37DCYaeDNB0sONDt",
            {
              email: "cobylyates@gmail.com",
              message: this.application.email,
            }
          );
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (this.$route.params) {
      let result = await positionsCollection
        .doc(`${this.$route.params.id}`)
        .get();
      this.application.positionType = result.data().position;
    }
    let result = await positionsCollection;
    result.onSnapshot((pos) => {
      this.positions = [];
      pos.forEach((p) => {
        this.positions.push(p.data().position);
      });
    });
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep input:-webkit-autofill,
// input:focus:-webkit-autofill {
//   box-shadow: 0 0 0 100px #2c2c2c inset!important;
//   color: white!important
</style>
